import api from '@motionelements/core/src/api/base-api-v2';

// eslint-disable-next-line
export const downloadOrderReceiptPdf = (orderId) => {
  return api.get(`/v2/orders/${orderId}/receipt`, {
    params: {
      ...api.getLocaleParams(),
    },
    responseType: 'blob',
    headers: {
      Accept: 'application/pdf', // use Accept header to download pdf
    },
  });
};
