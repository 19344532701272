import axios from 'axios';
import cookies from 'js-cookie';
import { camelCaseKeys, snakeCaseKeys } from '@motionelements/core/src/helpers/string.js';
import notificationService from '@motionelements/core/src/services/notification.service.js';
import axiosRetry from 'axios-retry';

import getLangFromUrl from '@motionelements/core/src/helpers/get-lang-from-url.js';

import Vue from 'vue';
// import store from '@/store/index.js';

const options = {
  language: null,
  currency: null,
};

let store;
// Nuxt compatibility workaround
export function setStore(s) {
  store = s;
}

const base = (function base() {
  // API VERSION
  // const ver = 'v2';
  // API VERSION DATEs
  const verDate = '2020-02-12';

  // API_URL_SERVER is used only for nuxt apps for correct working async fetch on localhost
  const baseUrl = (process.server && process.env.VUE_APP_API_URL_SERVER) || process.env.VUE_APP_API_URL;

  const isEnableCredential = process.env.NODE_ENV !== 'development';

  // console.log('node env:', process.env.NODE_ENV);

  /** me api w cookie for header and withCredentials setting */
  const pureAPI = axios.create({
    baseURL: baseUrl,
    headers: {
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${token}`,
      'Cache-Control': 'no-cache',
      'ME-Version': verDate,
    },
    withCredentials: false,
    // transform response data to camelCase
    transformResponse: [
      // data => (data ? camelCaseKeys(JSON.parse(data), { deep: true }) : {}),
      data => (data ? camelCaseKeys(JSON.parse(data)) : {}),
    ],
  });
  // intercept APIs response handle message
  pureAPI.interceptors.response.use(
    (response) => {
      notificationService.handleResponseNotification(response);
      return response;
    },
    (error) => {
      // pass error response to handler
      notificationService.handleResponseNotification(error.response);
      return Promise.reject(error);
    },
  );

  const setLanguage = (languageCode) => {
    options.language = languageCode;
  };

  const getLocaleParams = (context) => {
    const userCurrencyCode = store.getters['user/currencyCode'];
    let language = options.language || store.getters['user/languageCode'];
    // let language = 'en';
    // for NUXT
    // const isNuxt = typeof window !== 'undefined' && !!window.$nuxt;
    // if (context && isNuxt) {
    //   language = 'ko'; // getLangFromUrl(context.$route.fullPath);
    // }
    // console.log(context);
    if (process.server && context) {
      // language = _.get(store.state, 'i18n.locale'); // not working on prod
      // language = getLangFromUrl(store.state.route.fullPath); // not working on prod
      language = getLangFromUrl(_.get(context, '$route.fullPath', ''));
    }
    /*
    const isNuxt = typeof window !== 'undefined' && !!window.$nuxt;
    if (isNuxt) { // language code from url ONLY for nuxt
      // language = _.get(store.state, 'i18n.locale');
      language = getLangFromUrl(_.get(window, 'location.pathname', ''));
    }
     */
    //
    return {
      currency: userCurrencyCode,
      language,
    };
  };

  const request = (method, url, config) => {
    if (process.server && process.env.VUE_APP_ENV === 'development') {
      console.log(`%c ${method.toUpperCase()} ${url}`, 'color: orange');
      console.log(`%c ${JSON.stringify(config.params)}`, 'color: orange');
    }
    if (typeof config === 'undefined') {
      config = {};
    }

    let client;

    if (_.get(config, 'withCredentials') === false) {
      client = pureAPI;
    } else {
      // else create client with current token
      const token = cookies.get('ME_jwt');
      const storeValidationErrors = _.get(config, 'storeValidationErrors');

      client = axios.create({
        baseURL: baseUrl,
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'ME-Version': verDate,
          Authorization: token ? `Bearer ${token}` : '',
        },
        withCredentials: isEnableCredential,
        // transform response data to camelCase
        transformResponse: [(data) => {
          if (_.get(config, 'responseType') === 'blob') {
            return data;
          }
          // check that data is like a json
          if (data && data.indexOf('{') === 0) {
            try {
              return camelCaseKeys(JSON.parse(data));
            } catch (error) {
              // eat errors
            }
          }
          return {};
        }],
        // data => (data ? camelCaseKeys(JSON.parse(data), { deep: true }) : {}),
        //   (data, headers) => (data ? console.log('dddd', headers, data) && camelCaseKeys(JSON.parse(data)) : {}),
        // ],
        // transformRequest: [(data, headers) => {
        //   if (data && headers['Content-Type'].includes('application/json')) {
        //     return JSON.stringify(snakeCaseKeys(data));
        //   }
        // }],
      });

      axiosRetry(client, {
        retries: 1,
        retryDelay: (count) => 2 ** count + Math.random() * 1000,
        // retryDelay: axiosRetry.exponentialDelay,
        retryCondition: (err) => err.config.url.split('/').includes('search'),
      });

      client.interceptors.response.use(
        (response) => {
          notificationService.handleResponseNotification(response);
          return response;
        },
        (error) => {
          // eslint-disable-next-line default-case
          switch (_.get(error, 'response.status')) {
            case 401:
              if (!process.server) {
                sessionStorage.clear();
              }
              break;

            case 504: // gateway timeout
              // @todo dynamic import Vue only here when needed?
              if (!process.env.VUE_APP_NUXT) {
                // @todo check if rollbar is imported in all packages, include nuxt?
                Vue.rollbar.error(`gateway timeout: ${_.get(error.response, 'config.url')}`);
              }
              break;

            case 422:
              if (storeValidationErrors) store.commit('apiValidationErrors/setErrors', error.response);
          }

          notificationService.handleResponseNotification(error.response);

          return Promise.reject(error);
        },
      );
    }

    config.method = method;
    config.url = url;

    // lowercase for axois, so no need to convert
    switch (method) {
      case 'get':
      case 'delete':
        return client.request(config);
      case 'patch':
      case 'post':
      case 'put':
        // transform request data from camelCase to snake_case
        // headers is case-sensitive (axios bug?)
        config.transformRequest = [(data, headers) => {
          if (data && headers['Content-Type'].includes('application/json')) {
            return JSON.stringify(snakeCaseKeys(data));
          }
        }];
        return client.request(config);
      default:
        return false;
    }
  };

  const requestMock = (method, url, config) => {
    if (typeof config === 'undefined') {
      config = {};
    }

    // else create client with current token
    // const token = cookies.get('ME_jwt');
    // console.log(`token:${token}`);
    // const token = store.getters['cookie/getApiToken'];
    // get new
    const client = axios.create({
      baseURL: process.env.VUE_APP_API_MOCK_URL,
      // baseURL: process.env.VUE_APP_API_URL,
      headers: {
        'api-version': '2020-02-12',
        'x-api-key': process.env.VUE_APP_MOCK_API_KEY,
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'ME-Version': verDate,
        // Authorization: `Bearer ${token}`,
      },
      // withCredentials: isEnableCredential,
      withCredentials: false,
      // transform response data to camelCase
      transformResponse: [
        data => (data ? camelCaseKeys(JSON.parse(data)) : {}),
      ],
    });

    client.interceptors.response.use(
      (response) => {
        notificationService.handleResponseNotification(response);
        return response;
      },
      (error) => {
        // pass error response to handler
        notificationService.handleResponseNotification(error.response);
        return Promise.reject(error);
      },
    );

    config.method = method;
    config.url = url;

    // lowercase for axois, so no need to convert
    switch (method) {
      case 'get':
      case 'delete':
        return client.request(config);
      case 'patch':
      case 'post':
      case 'put':
        // transform request data from camelCase to snake_case
        // headers is case-sensitive (axios bug?)
        config.transformRequest = [(data, headers) => {
          if (data && headers['Content-Type'].includes('application/json')) {
            return JSON.stringify(snakeCaseKeys(data));
          }
        }];
        return client.request(config);
      default:
        return false;
    }
  };

  const get = (url, config) => request('get', url, config);

  return {
    get,
    request,
    requestMock,
    getLocaleParams,
    setLanguage,
  };
}());

export default base;
