import Vue from 'vue';
import * as elementsApi from '@motionelements/core/src/api/elements.js';
import { isElementVariantDownloadableBySubscription } from '@motionelements/core/src/services/download.service.js';
import { isProductType, isFileBasedMediaType } from '@motionelements/core/src/services/catalog.service.js';
import loadingMutation from '@motionelements/core/src/store/mutations-mixins/loadingMutation.js';
import * as appApi from '@motionelements/core/src/api/app.js';

export const state = () => ({
  current: null,
  elementSchema: {},
  //
  archiveEntries: [],
  stems: [],
  stemsCollapsed: true,
  //
  prev: null,
  next: null,
  //
  loading: false,
});

export const getters = {
  masterVariant: (state) => {
    const variants = _.get(state.current, 'variants', []);
    return variants.find(({ master }) => master) || variants[0] || null;
  },
  isFree: (state, getters) => {
    if (getters.masterVariant) {
      return getters.masterVariant.free;
    }
    return state.current.free;
  },
  isPro: (state) => _.get(state.current, 'usageRights.pro', false),
  isLoopable: (state) => _.get(state.current, 'mediaTypeDetails.loop', false),
  isDownloadable: (state, getters) => {
    if (getters.masterVariant) {
      return getters.masterVariant.downloadable;
    }
    return false;
  },
  isSubscription: (state, getters) => {
    if (getters.masterVariant) {
      return getters.masterVariant.subscription;
    }
    return false;
  },
  isExclusive: (state) => _.get(state.current, 'exclusive'),
  // eslint-disable-next-line
  isAiGenerated: ({ current: product }) => {
    return _.get(product, 'categories', []).map(({ id }) => id).includes('823'); // 823 - AI generated
  },
  mediaType: ({ current: product }) => _.get(product, 'mediaType'),
  previewType: (state, getters) => {
    switch (true) {
      case isProductType('video_template', getters.mediaType):
        return 'template';
      case isProductType('vr', getters.mediaType):
        return 'vr';
      // case isProductType('3d', getters.mediaType):
      //   return '3d';
      case isProductType('audio', getters.mediaType):
        return 'audio';
      case isProductType('video', getters.mediaType):
        return 'video';
      default:
        return 'image';
    }
  },
  isDownloadableDirectly(state, getters, rootState) {
    if (getters.isFree) {
      return true;
    }
    if (isElementVariantDownloadableBySubscription(state.current, getters.masterVariant, rootState.user.member.subscriptions)) {
      return true;
    }
    return getters.isDownloadable;
  },
  currentVariantFormat: (state, getters) => _.get(getters.masterVariant, 'format'),
  // eslint-disable-next-line
  isFileBasedProduct: (state, getters) => {
    return isFileBasedMediaType(getters.mediaType) && getters.currentVariantFormat !== 'url';
  },
  downloadMethod: (state, getters, rootState) => (variant) => {
    switch (true) {
      case isElementVariantDownloadableBySubscription(state.current, variant, rootState.user.member.subscriptions):
        return 'subscription';
      case getters.isFree:
        return 'free';
      case getters.isDownloadable:
        return 'redownload';
      default:
        return false;
    }
  },
  isSimilaritySearchEnabled: (state) => _.get(state.current, 'similarity'),
  hasSample: (state, getters) => {
    // if no preview
    if (!_.size(state.current.previews)) {
      return false;
    }

    return ['photo', 'photo_vr'].includes(getters.mediaType)
      || isProductType('video', getters.mediaType)
      || isProductType('audio', getters.mediaType)
      || isProductType('video_template', getters.mediaType);
  },
  hasStem: (state) => _.get(state.current, 'stem'),
  // eslint-disable-next-line
  archiveEntriesExtensions: (state) => {
    return state.archiveEntries.map(({ title }) => title.split('.').pop());
  },
  duration: (state) => _.get(state.current, 'mediaTypeDetails.duration'),
};

export const actions = {
  async fetchProduct({ commit }, id) {
    commit('setLoading', true);
    const { data: { data: product } } = await elementsApi.getElement(id, { 'fields[element]': 'details' });
    // product.previews.jpg.url = 'https://res.cloudinary.com/dlqfcadfs/image/upload/v1742230153/05b297bf0898671050d8213095bc4818_vupx8y.png';
    // product.previews.jpg.url = 'https://thumb.r2.moele.me/t/44034/44024858/a-01.jpg';
    // product.previews.jpg.url = 'https://thumb.r2.moele.me/t/44077/44067308/a-01.jpg';
    // product.previews.jpg.url = 'https://thumb.r2.moele.me/t/43996/43986836/a-01.jpg';
    // product.previews.jpg.url = 'https://d25thuhsbcr6yb.cloudfront.net/m/s/32530/32520454/a-01.jpg';
    // product.previews.jpg.url = 'https://d25thuhsbcr6yb.cloudfront.net/m/s/44112/44102436/a-01.jpg';
    // product.previews.jpg.url = 'https://thumb.r2.moele.me/t/44016/44006512/a-01.jpg';
    // product.previews = {
    //   jpg: {
    //     url: 'https://d25thuhsbcr6yb.cloudfront.net/m/s/43758/43748573/a-0005.jpg',
    //   },
    //   mp4: {
    //     url: 'https://video.r2.moele.me/v/43758/43748573_a-01.mp4',
    //   },
    // };
    // product.usageRights.pro = true;
    // product.mediaTypeDetails.loop = true;
    // product.similarity = true;
    if (product) commit('setProduct', product);
    // commit('setLoading', false);
  },
  async fetchProductSchema({ commit }) {
    const { data: { data: schema } } = await appApi.listProductSchema();
    if (schema) commit('setElementSchema', schema);
  },
  getEmbedCode({ commit }, payload) {
    return elementsApi.getEmbedCode(payload.id)
      .then((response) => {
        commit('setEmbedCode', response.data.data.html);
        return response;
      });
  },
  //
  async fetchArchiveEntries({ commit }, sku) {
    const { data: { data } } = await elementsApi.listElementArchiveEntries(sku);
    if (data) commit('setArchiveEntries', data);
  },
  async fetchStems({ commit }, id) {
    try {
      const { data: { data: stems } } = await elementsApi.listElementStems(id);
      if (stems) commit('setStems', stems);
    } catch (err) {
      console.error(err);
    }
  },
};

export const mutations = {
  setProduct(state, product) {
    // product.variants[1].format = '4k';
    state.current = product;
  },
  setElementSchema(state, schema) {
    state.elementSchema = schema;
  },
  setEmbedCode(state, data) {
    Vue.set(state.current, 'embedCode', data);
  },
  setArchiveEntries(state, data) {
    state.archiveEntries = data;
  },
  setStems(state, stems) {
    state.stems = stems;
  },
  setStemsCollapsed(state, boolean) {
    state.stemsCollapsed = boolean;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations: { ...mutations, ...loadingMutation },
  actions,
};
