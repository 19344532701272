import api from '@motionelements/core/src/api/base-api-v2';

export const createDownload = async (payload) => {
  const url = '/v2/downloads';
  return api.request('post', url, {
    data: payload,
    params: api.getLocaleParams(),
  });
};

export const getDownloads = (params, axiosConfig) => {
  const url = '/v2/downloads';
  return api.get(url, {
    params: {
      ...api.getLocaleParams(),
      ...params,
    },
    ...(axiosConfig || {}),
  });
};

export const getDownload = async (id, params) => {
  const url = `/v2/downloads/${id}`;
  return api.get(url, {
    params: {
      ...api.getLocaleParams(),
      ...params,
    },
  });
};

// eslint-disable-next-line
export const getDownloadVariants = (downloadSku) => {
  return api.get(`/v2/downloads/${downloadSku}/variants`);
};

// eslint-disable-next-line
export const getDownloadConvertedVariants = (downloadId, orderId) => {
  return api.get(`/v2/downloads/${downloadId}/converted-variants`, {
    params: {
      order: orderId,
    },
  });
};

// eslint-disable-next-line
export const updateDownloadAnnotation = async (elementId, payload) => {
  return api.request('post', `/v2/elements/${elementId}/annotate`, {
    data: payload,
  });
};

// eslint-disable-next-line
export const convertDownload = ({ downloadId, payload, axiosConfig }) => {
  return api.request('post', `/v2/downloads/${downloadId}/convert`, {
    data: payload, // { preset: 'wmv' }
    ...(axiosConfig || {}),
  });
};

// eslint-disable-next-line
export const downloadSample = (elementId, type, axiosConfig) => {
  return api.request('post', `/v2/samples/${elementId}/download`, {
    data: {
      type, // mediaTypeGroup ('video', 'audio', ...)
    },
    ...(axiosConfig || {}),
  });
};

// eslint-disable-next-line
export const deleteConvertedFile = (fileId, axiosConfig) => {
  return api.request('delete', `/v2/files/${fileId}`, {
    ...(axiosConfig || {}),
  });
};
