import api from '@motionelements/core/src/api/base-api-v2';

export const getElement = async (sku, params) => {
  params = params || {};
  return api.get(`/v2/elements/${sku}`, {
    params: {
      ...api.getLocaleParams(),
      ...params,
    },
  });
};

export const listElementStems = async (id) => {
  const url = `/v2/elements/${id}/stems`;
  return api.get(url, {
    params: {
      ...api.getLocaleParams(),
    },
  });
};

// eslint-disable-next-line
export const flagProduct = (id, data) => {
  return api.request('post', `/v2/elements/${id}/flag`, {
    data,
  });
};

// get element compitable software version
export const getEmbedCode = async (id) => {
  const url = `/v2/elements/${id}/embed-code`;
  return api.get(url);
};

export const listElementArchiveEntries = async (sku) => {
  const url = `/v2/elements/${sku}/archive-entries`;
  return api.get(url);
};
