export default function (data, fileName, ext) {
  const type = {};
  if (ext === 'pdf') type.type = 'application/pdf';
  const fileURL = URL.createObjectURL(new Blob([data], type));
  const fileLink = document.createElement('a');
  fileLink.href = fileURL;
  fileLink.setAttribute('download', `${fileName}.${ext}`);
  document.body.appendChild(fileLink);

  fileLink.click();
  fileLink.remove();
  URL.revokeObjectURL(fileURL);
}
