import api from '@motionelements/core/src/api/base-api-v2';

export const getLicenseCertificate = ({ orderId, axiosConfig }) => api.get(`/v2/license-certificates/${orderId}`, {
  ...(axiosConfig || {}),
});

export const downloadLicenseCertificatePdf = ({ orderId }) => api.get(`/v2/license-certificates/${orderId}`, {
  responseType: 'blob',
  headers: {
    Accept: 'application/pdf', // use Accept header to download pdf
  },
  params: {
    ...api.getLocaleParams(),
  },
});
