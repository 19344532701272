export default {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setIsLoaded(state, boolean) {
    state.isLoaded = boolean;
  },
  setError(state, error) {
    state.error = error;
  },
};
