import * as accountApi from '@motionelements/core/src/api/account.js';
import * as downloadsApi from '@motionelements/core/src/api/downloads.js';
import * as licenseCertificatesApi from '@motionelements/core/src/api/license-certificates.js';
import { downloadOrderReceiptPdf } from '@motionelements/core/src/api/orders.js';
import createDownloadLink from '@motionelements/core/src/helpers/create-download-link.js';

export const downloadElement = async payload => downloadsApi.createDownload(payload);

export const purchaseCollection = async payload => downloadsApi.createDownload(payload);

export const getDownload = async (id, params) => downloadsApi.getDownload(id, params).then(response => response.data);

/**
 * check can member subscription can download
 * @param {Object} element element.media_type
 * @param {Object} variant
 * @param {Array} memberSubscriptions member.info.subscrpition
 */
export const isElementVariantDownloadableBySubscription = (element, variant, memberSubscriptions = []) => {
  if (!_.get(variant, 'subscription')) {
    return false;
  }

  if (memberSubscriptions.includes('unlimited')) {
    return true;
  }

  function getElementSubscriptionPlanType(element) {
    switch (_.get(element, 'mediaType')) {
      case 'photo':
      case 'photo_vr':
      case 'vector':
      case 'gif':
      case 'lottie':
        return 'image';
      case 'music':
      case 'ae_template':
      case 'ae_preset':
      case 'pr_template':
      case 'pr_preset':
      case 'mg_template':
        return 'music-ae-pr';
      case 'video':
      case 'video_live':
      case 'video_vr':
      case 'animation':
      case 'motion_template':
        return 'video-motion';
      default:
        return 'unlimited';
    }
  }

  return memberSubscriptions.includes(getElementSubscriptionPlanType(element));
};

export async function getFreeDownloadsRemaining() {
  return accountApi.getFreeDownloadsRemaining()
    .then(res => res.data)
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
}

// eslint-disable-next-line
export const downloadLicenseCertificatePdf = (orderId) => {
  return licenseCertificatesApi.downloadLicenseCertificatePdf({
    orderId,
  })
    .then((response) => {
      createDownloadLink(response.data, `MotionElements-license-certificate-${orderId}`, 'pdf');
    })
    .catch(err => {
      console.error(err);
    });
};

// eslint-disable-next-line
export const downloadReceiptPdf = orderId => {
  return downloadOrderReceiptPdf(orderId)
    .then((response) => {
      createDownloadLink(response.data, `MotionElements-receipt-${orderId}`, 'pdf');
    })
    .catch(err => {
      console.error(err);
    });
};
