// eslint-disable-next-line
const state = () => {
  return {
    id: null,
    play: false,
  };
};

const mutations = {
  play(state, id) {
    state.id = id;
  },
  reset(state) {
    state.id = null;
    state.play = false;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
