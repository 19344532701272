import Vuex from 'vuex';

import app from './app.js';
import audioPlayer from './audioPlayer.js';
import audioPlayer2025 from './audioPlayer2025.js';
import blocker from './blocker.js';
import browser from './browser.js';
import cart from './cart.js';
import channels from './channels.js';
import cookie from './cookie.js';
import experiment from './experiment.js';
import faqs from './faqs.js';
import favorites from './favorites.js';
import modal from './modal.js';
import notification from './notification.js';
import product from './product.js';
import profile from './profile.js';
import route from './route.js';
import searchBox from './searchBox.js';
import subscription from './subscription.js';
import user from './user.js';
import videoPlayer from './videoPlayer.js';

export const actions = {
  // eslint-disable-next-line
  async nuxtServerInit({ commit }, { route }) {
    console.log('========== nuxtServerInit ==========');
    commit('route/setFullPath', route.fullPath);
  },
};

export default () => new Vuex.Store({
  modules: {
    app,
    audioPlayer,
    audioPlayer2025,
    blocker,
    browser,
    cart,
    channels,
    cookie,
    experiment,
    faqs,
    favorites,
    modal,
    notification,
    product,
    profile,
    route,
    searchBox,
    subscription,
    user,
    videoPlayer,
  },
  actions,
});
